module.exports = {
  siteTitle: 'WinterSongs', // Site title.
  siteTitleAlt: 'WinterSongs', // Alternative site title for SEO.
  siteLogo: './static/icons/icon.png', // Logo used for SEO and manifest.
  siteUrl: 'https://wintersongs.org', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: `Charlottesville's local vocal music teachers partner with The Shelter for Help in Emergency to sponsor the WinterSongs concert on the final Saturday of February. The participants are young female vocalists from our area schools. The day is filled with sharing, musical collaboration, mutual support, and empowerment.`, // Website description used for RSS feeds/meta description tag.
  copyright: 'Copyright © WinterSongs 2019-2023. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00247C', // Used for setting manifest and progress theme colors.
  backgroundColor: '#00247C', // Used for setting manifest background color.
}
