import React, { Component } from 'react'
import Helmet from 'react-helmet'
import '../../assets/sass/styles.scss'
import config from '../../../config'
import NavBar from '../NavBar'
import Footer from '../Footer'
import SecretWindow from '../SecretWindow'

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = { isActive: false }
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar () {
    this.setState({ isActive: !this.state.isActive })
  }

  render () {
    const { secret_window } = this.props;
    return (
      <div className="layout">
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name='description' content={config.siteDescription} />
        </Helmet>
        {
          typeof window !== `undefined` ? <SecretWindow secret_window={secret_window}/> : <></>
        }
        <NavBar isActive={this.state.isActive} toggleNavbar={() => this.toggleNavbar()} />
        <main>
          {this.props.children}
        </main>
        <Footer />
      </div>
    )
  }
}

export default Layout
