import React from 'react';

function InternalLink(props) {
  const navigate = (e) => {
    e.preventDefault();
    const { target: { hash }} = e;
    const target = document.querySelector(hash);
    const top = target.offsetTop;
    window.scroll({
      top: top - 160,
      left: 0,
      behavior: 'smooth'
    });
  }

  const { to } = props;
  return (
    <a href={to} onClick={navigate}>
      {props.children}
    </a>
  );
}

export default InternalLink;