import React from 'react';
import { Link } from 'gatsby'

import InternalLink from '../InternalLink';
import logo from '../../assets/img/logo.png';

const NavBar = ({ toggleNavbar, isActive }) => (
  <nav className="navbar is-fixed-top" aria-label="main navigation">
    <div className="navbar__container container">
      <HamburgerMenu>
        <NavLinks />
      </HamburgerMenu>
      <div className="navbar-brand">
        <Link to="/">
          <img className="logo" src={logo} alt="Winter Songs"/>
        </Link>
      </div>
      <div className="spacer"></div>
      <div className="navbar__phrase">
        <p>Empowering women</p>
        <p>Eradicating relationship violence</p>
        <p>Promoting healthy self esteem</p>
      </div>
    </div>
  </nav>
);

const NavLinks = () => (
  <>
    <InternalLink to="#about">About</InternalLink>
    <InternalLink to="#history">History</InternalLink>
    <InternalLink to="#donate">Donations</InternalLink>
    <InternalLink to="#this-year">This Year</InternalLink>
    <InternalLink to="#media">Media</InternalLink>
    <InternalLink to="#sister_concerts">Sister Concerts</InternalLink>
    <InternalLink to="#contact">Contact</InternalLink>
    <InternalLink to="#links">Links</InternalLink>
  </>
);

const HamburgerMenu = (props) => (
<nav className="hamburger" role="navigation">
  <div id="menuToggle">
    <input type="checkbox" />
    <span></span>
    <span></span>
    <span></span>
    <div id="menu">
      { props.children }
    </div>
  </div>
</nav>
);

export default NavBar;
