import React from 'react';

import eric from '../../../static/img/eric.jpg';

function SecretWindow({secret_window, ...props}) {
  const { secret_code, title, blurb } = secret_window;

  const openWindow = () => document.querySelector('.layout').setAttribute('data-modal', true);
  const closeWindow = () => document.querySelector('.layout').removeAttribute('data-modal');

  const isInput = (tagName) => tagName === 'INPUT' || tagName === 'TEXTAREA';

  const pressed = [];
  const lowerCode = secret_code.toLowerCase();
  window.addEventListener('keyup', (e) => {
    const tagName = document.activeElement.tagName;
    pressed.push(e.key.toLowerCase());
    pressed.splice(-lowerCode.length - 1, pressed.length - lowerCode.length);
    if (pressed.join('').includes(lowerCode) && !isInput(tagName)) {
      openWindow();
    }
  });

  return (
    <div className="secret_window_box" onClick={closeWindow}>
      <div id="secret_window" className="">
        <button title="close dialog" aria-label="Close Dialog" onClick={closeWindow}>X</button>
        <h1>{title}</h1>
        <div className="image_container">
          <img src={eric} alt="" aria-hidden/>
        </div>
        <p>{blurb}</p>
      </div>
    </div>
  );
}

export default SecretWindow;